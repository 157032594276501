import React, { FunctionComponent } from "react"
import Layout from "../layout/index"
import schema from "./index-schema.json"

import "./imprint.css"

const ImprintPage: FunctionComponent<Record<string, unknown>> = () => (
  <Layout
    lang="de"
    title="codaline - Impressum"
    description="Remote Softwareentwicklung, Beratung, Prototyping & Code Audits"
    microdata={JSON.stringify(schema)}
  >
    <div className="main-wrapper">
      <h1>Impressum</h1>
      <p>Herausgeber und Inhaber der Domain: Marcus Kirsch und Bengt Weiße.</p>
      <div className="row">
        <div className="column">
          <h3>Standort Buttstädt OT Hardisleben</h3>
          <p>
            Marcus Kirsch und Bengt Weiße GbR
            <br />
            <strong>codaline</strong>
            <br />
            Schulstr. 2b
            <br />
            99628 Buttstädt OT Hardisleben
          </p>
          <p>
            Ansprechpartner: Bengt Weiße
            <br />
            <a href="mailto:bengt@codaline.io">bengt@codaline.io</a>
          </p>
        </div>
        <div className="column">
          <h3>Standort Weimar</h3>
          <p>
            Marcus Kirsch und Bengt Weiße GbR
            <br />
            <strong>codaline</strong>
            <br />
            Papiergraben 1
            <br />
            99425 Weimar
          </p>
          <p>
            Ansprechpartner: Marcus Kirsch
            <br />
            <a href="mailto:marcus@codaline.io">marcus@codaline.io</a>
          </p>
        </div>
      </div>
      <h3>Bankverbindung</h3>
      <p>
        Konto: solarisBank AG <br />
        IBAN: DE75 1101 0100 2798 1330 69 <br />
        BIC: SOBKDEBBXXX
      </p>
      <h3>Haftungshinweise</h3>
      <p>
        Die Marcus Kirsch & Bengt Weiße GbR (im folgenden als codaline
        bezeichnet) übernimmt keine Garantie oder Haftung dafür, dass die auf
        dieser Website bereitgestellten Informationen vollständig, richtig und
        in jedem Fall aktuell sind. Dies gilt auch für alle Verbindungen (Links)
        auf die diese Website direkt oder indirekt verweist. Codaline ist für
        den Inhalt einer Seite, die mit einem solchen Link erreicht wird, nicht
        verantwortlich. Codaline behält sich das Recht vor, ohne vorherige
        Ankündigung, Änderungen oder Ergänzungen der bereitgestellten
        Informationen vorzunehmen. Sämtliche Inhalte, Bilder, Graphiken,
        Animationen etc. sind urheberrechtlich geschützt. Jede unerlaubte
        Nutzung wird ohne Vorwarnung zivil- und strafrechtlich verfolgt.
      </p>
    </div>
  </Layout>
)

export default ImprintPage
